import * as React from "react";
import {MdHome, MdLanguage} from "react-icons/md";
import Zoom from "react-reveal/Zoom"
import Seo from "../components/seo";
import NavBar from "../components/navBar";
import {StaticImage} from "gatsby-plugin-image";
import {Link} from "gatsby";
import {useTranslation} from "react-i18next";

export default function Info() {
    const {t} = useTranslation("info")
    return (
        <div className="flex flex-col h-full">
            <Seo title="Info" description={t("aboutQubtimetable")}/>
            <NavBar title="Info" navButton={{icon: MdHome, location: "/"}}/>
            <div className="h-full w-full overflow-y-auto">
                <div className="flex min-h-full justify-center items-center text-white py-2">
                    <Zoom cascade>
                        <div className="w-3/4 md:w-1/2 flex-col justify-center items-center space-y-3">
                            <div className="flex-col justify-center items-center space-y-1">
                                <div className="flex justify-center items-center w-3/4 md:w-1/2 mx-auto">
                                    <StaticImage
                                        src="../images/logo.png"
                                        alt="qubtimetable logo"
                                        placeholder="tracedSVG"
                                        layout="constrained"
                                    />
                                </div>
                                <p className="text-white text-center w-full md:w-3/4 mx-auto text-lg">{t("aboutQubtimetable")}</p>
                            </div>
                            <div className="border-b-2 border-white w-full"/>
                            <div className="flex-col justify-center items-center space-y-1">
                                <h1 className="text-2xl md:text-3xl text-center">{t("futureProjects")}</h1>
                                <div className="flex justify-center items-center w-3/4 md:w-1/2 mx-auto">
                                    <StaticImage
                                        src="../images/transp_1920x1080.png"
                                        alt="qubuilder logo"
                                        placeholder="tracedSVG"
                                        layout="constrained"
                                    />
                                </div>
                                <p className="text-white text-center w-full md:w-3/4 mx-auto text-lg">{t("aboutQubuilder")}</p>
                                <div className="flex justify-center items-center text-orange text-xl space-x-1">
                                    <MdLanguage/>
                                    <a className="text-center" href="https://qubuilder.com">www.qubuilder.com</a>
                                </div>
                                <div className="flex justify-center items-center text-orange">
                                    <Link to="/privacy-policy"
                                          className="text-center text-md md:text-xl lg:text-2xl text-orange">Privacy
                                        Policy</Link>
                                </div>
                            </div>
                        </div>
                    </Zoom>
                </div>
            </div>
        </div>
    )
}
